@import "fonts";
@import "variables";
@import "wp-blocks";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply text-foreground;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-lunchbox font-medium uppercase;
  }

  [x-cloak] {
    display: none !important;
  }

  .screen-reader-text {
    @apply sr-only;
  }

  .wp-block-content {
    h1 {
      @apply text-5xl;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      @apply uppercase my-2;
    }
  }

  [type="text"],
  input:where(:not([type])),
  [type="email"],
  [type="url"],
  [type="password"],
  [type="number"],
  [type="date"],
  [type="datetime-local"],
  [type="month"],
  [type="search"],
  [type="tel"],
  [type="time"],
  [type="week"],
  [multiple],
  textarea,
  select {
    @apply bg-white border shadow-none rounded leading-tight font-medium p-3 focus:ring-primary;

    border-color: rgb(107 114 128);
  }

  input[type="checkbox"] {
    @apply text-primary focus:ring-primary;
  }

  .select2-container {
    .select2-selection,
    .select2-selection.select2-selection--single {
      @apply flex items-center h-[40px] bg-white border rounded px-1 py-5 font-medium shadow-none focus:ring-primary;

      border-color: rgb(107 114 128);
    }

    .select2-selection__arrow {
      @apply !top-[7px] !w-[30px];

      b {
        border-color: rgb(107 114 128) transparent transparent !important;
      }
    }

    .select2-dropdown {
      @apply bg-white border;

      border-color: rgb(107 114 128);
    }
  }

  /* stylelint-disable */
  @media screen(lg) {
    [x-data="primaryMenu"]:has(nav > ul > li.has-children:hover)
      .navigation-overlay {
      @apply visible opacity-100;
    }
  }

  .woocommerce-order-pay,
  .woocommerce-checkout {
    ul.woocommerce-error {
      @apply bg-destructive text-white p-2 mb-2 rounded-lg text-sm;
    }

    .wcnlpc-manual {
      @apply text-sm;
    }

    .woocommerce-validated {
      input[type="text"],
      input[type="number"],
      input[type="email"],
      input[type="tel"],
      select,
      input[type="text"]:-webkit-autofill,
      input[type="number"]:-webkit-autofill,
      input[type="email"]:-webkit-autofill,
      input[type="tel"]:-webkit-autofill,
      select:-webkit-autofill,
      input[type="text"]:-webkit-autofill:hover,
      input[type="number"]:-webkit-autofill:hover,
      input[type="email"]:-webkit-autofill:hover,
      input[type="tel"]:-webkit-autofill:hover,
      select:-webkit-autofill:hover,
      input[type="text"]:-webkit-autofill:focus,
      input[type="number"]:-webkit-autofill:focus,
      input[type="email"]:-webkit-autofill:focus,
      input[type="tel"]:-webkit-autofill:focus,
      select:-webkit-autofill:focus {
        @apply relative ring-1 ring-successful pr-7 border-successful;
      }

      .select2-container .select2-selection {
        @apply relative ring-1 ring-successful border-successful;
      }

      &:has(input[type="text"]),
      &:has(input[type="number"]),
      &:has(input[type="email"]),
      &:has(input[type="tel"]),
      &:has(select) {
        .woocommerce-input-wrapper {
          @apply relative;

          &:after {
            @apply absolute bottom-0 right-0 size-4 mr-3;

            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 32 32%27%3E%3Cpath fill=%27%2300b900%27 d=%27M15.1 26.3a3.2 3.2 0 0 1-4.6 0L1 16.3a3.5 3.5 0 0 1 0-4.7 3.2 3.2 0 0 1 4.6 0l7.3 7.6L26.5 5A3.2 3.2 0 0 1 31 5a3.5 3.5 0 0 1 0 4.7L15 26.3z%27/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 1em 1em;
            content: "";
          }
        }
      }

      &:has(select) {
        .woocommerce-input-wrapper {
          &:after {
            @apply mr-8 -mb-1;
          }
        }
      }
    }

    .woocommerce-invalid {
      input[type="text"],
      input[type="number"],
      input[type="email"],
      input[type="tel"],
      select {
        @apply relative ring-1 ring-destructive;
      }

      .select2-container .select2-selection {
        @apply relative ring-1 ring-destructive;
      }
    }

    .woocommerce-shipping-methods li:has(input[type="radio"]:checked),
    .wc_payment_methods li:has(input[type="radio"]:checked) {
      @apply !bg-secondary-200;
    }
  }

  .flex-control-thumbs {
    @apply grid grid-cols-4 gap-4 mt-2;

    @screen lg {
      @apply grid-cols-5;
    }

    @screen xl {
      @apply grid-cols-6;
    }

    > li {
      @apply p-1 border border-muted cursor-pointer;

      img {
        @apply w-full h-full object-cover;
      }

      &:has(.flex-active) {
        @apply border-primary;
      }
    }
  }

  .flex-direction-nav {
    .flex-prev {
      @apply absolute left-0 top-0 z-[3] mt-[190px] bg-primary text-white size-8 rounded-full;

      text-indent: -9999px;
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.2426 6.34317L14.8284 4.92896L7.75739 12L14.8285 19.0711L16.2427 17.6569L10.5858 12L16.2426 6.34317Z' fill='white'%3E%3C/path%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 1.5rem 1.5rem;
    }

    .flex-next {
      @apply absolute right-0 top-0 z-[3] mt-[190px] bg-primary text-white size-8 rounded-full;

      text-indent: -9999px;
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.5858 6.34317L12 4.92896L19.0711 12L12 19.0711L10.5858 17.6569L16.2427 12L10.5858 6.34317Z' fill='white'%3E%3C/path%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 1.5rem 1.5rem;
    }
  }

  #yith-par-message-reward-cart {
    @apply bg-secondary-200 py-3 px-4 mb-4 rounded-lg;

    form {
      @apply flex flex-wrap items-center gap-2;

      input {
        @apply p-2;

        max-width: 60px;
      }

      button {
        @apply px-2 py-1 bg-secondary font-semibold text-base text-white rounded-lg border-b-4 border-b-secondary-600 border-l-secondary-600;

        &:hover {
          @apply bg-secondary-600 border-secondary-600;
        }
      }
    }
  }

  .msp-container-ui {
    .msp-ui-control-error-message {
      font-size: 13px !important;
    }

    .msp-ui-payment-form {
      padding-top: 0 !important;
    }

    .msp-ui-form-control {
      @apply bg-white border shadow-none rounded leading-tight font-medium p-3 focus:ring-primary;

      border-color: rgb(107 114 128) !important;
      height: 46px;
    }

    div.msp-ui-form-control {
      padding-top: 10px !important;
    }

    .msp-ui-control-error {
      border-color: theme("colors.destructive.DEFAULT") !important;
    }

    .msp-ui-form-group:not(:last-child) {
      @apply mb-2;
    }

    .msp-ui-row {
      @apply mt-2;
    }

    .msp-ui-control-error-space:empty {
      display: none;
    }

    .msp-ui-tooltip-image {
      height: 18px !important;
    }
  }
}

@layer utilities {
  .has-text-align-center {
    @apply text-center;
  }

  .hide-empty {
    &:not(:has(> *)) {
      display: none;
    }
  }

  .shadow-around {
    box-shadow:
      0 20px 50px 15px rgba(0, 0, 0, 0.075),
      0 10px 10px -8px rgba(0, 0, 0, 0.06);
  }
}
